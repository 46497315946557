import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { Alert } from '@mui/material';

import { API_BASE_URL, getSearchResults, healthCheck } from '../api/serpApi';
import Spinner from '../components/Spinner';
import MainLayout from '../layouts/MainLayout';
import useConnect from '../hooks/useConnect';

function LorPage() {
  const [leftPercent, setLeftPercent] = useState<number>(0);
  const [rightPercent, setRightPercent] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<'warning' | 'info'>('info');
  const [alertMessage, setAlertMessage] = useState<string>('');

  useConnect(`${API_BASE_URL}/health`);

  const handleOnKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleOnSubmit();
    }
  }, []);

  const handleChangeSearchText = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), []);

  const setAlert = useCallback((severity: 'warning' | 'info', message: string) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setIsAlert(true);
  }, []);

  const handleOnSubmit = useCallback(() => {
    setLeftPercent(0);
    setRightPercent(0);
    setIsLoading(true);
    setIsAlert(false);

    getSearchResults(searchText)
      .then((res) => {
        const result = res;
        const total = result.left + result.right;

        if (total === 0) {
          setAlert('info', 'No results found');
        } else {
          const leftPercent = Math.round((result.left / total) * 100);

          setLeftPercent(leftPercent);
          setRightPercent(100 - leftPercent);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert('warning', 'You have exceeded your monthly limit. Please try again later.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchText]);

  return (
    <MainLayout>
      <div className='flex flex-col items-center space-y-5 justify-start'>
        {isAlert ? (
          <Alert severity={alertSeverity} className='w-5/6 md:w-1/3'>
            {alertMessage}
          </Alert>
        ) : null}
        <input
          className='h-10 border-2 border-gray-300 rounded-lg p-2 w-5/6 md:w-1/3'
          type='text'
          placeholder='Enter a name to know the political side'
          value={searchText}
          onChange={handleChangeSearchText}
          onKeyUp={handleOnKeyUp}
        />
        <button className='h-10 pt-2 pb-2 bg-gray-300 rounded-lg px-2 flex items-center space-x-2 space-y-2 flex-col' onClick={() => handleOnSubmit()}>
          Submit
        </button>
        <div className='flex space-x-10 justify-between'>
          <div className='flex flex-col items-center font-medium'>
            <p>LEFT</p>
            <label className='flex items-center space-x-2'>{leftPercent}%</label>
          </div>
          <div className='flex flex-col items-center font-medium'>
            <p>RIGHT</p>
            <label className='flex items-center space-x-2'>{rightPercent}%</label>
          </div>
        </div>
        {isLoading ? <Spinner /> : <div className='w-8 h-8'></div>}
        <div className='w-full text-center'>
          <span className='font-bold'>Disclaimer:</span> <br />
          This application is based on internet search. <br />
          The result may not be correct and should not be used as a reference. <br />
          The app owner has no responsibility for the results.
        </div>
      </div>
    </MainLayout>
  );
}

export default LorPage;
