import axios from 'axios';

export const API_BASE_URL = process.env.GATSBY_API_SEARCH_BASE_URL;

export const healthCheck = async () => {
  const response = await axios.get(`${API_BASE_URL}/health`);
  return response.data;
};

const fetchResults = async (query: string) => {
  const response = await axios.get(`${API_BASE_URL}/search?q=${query}`);
  return response.data;
};

export const getSearchResults = async (query: string) => {
  const data = await fetchResults(query);

  let result: string = JSON.stringify(data['answer_box']) + ' ' + JSON.stringify(data['organic_results']) + ' ' + JSON.stringify(data['related_questions']);

  let leftEn = result.match(/left/g)?.length || 0;
  let rightEn = result.match(/right/g)?.length || 0;
  let leftFr = result.match(/gauche/g)?.length || 0;
  let rightFr = result.match(/droite/g)?.length || 0;

  let left = leftEn + leftFr;
  let right = rightEn + rightFr;

  return { left, right };
};
