import { useEffect, useState } from 'react';

import axios from 'axios';

const useConnect = (url: string) => {
  const [refesh, setRefesh] = useState<number>(0);
  const [connectState, setConnectState] = useState<string>('CONNECTING'); // ['CONNECTING', 'CONNECTED', 'DISCONNECTED', 'ERROR']

  useEffect(() => {
    setConnectState('CONNECTING');
    axios
      .get(url)
      .then(() => {
        setConnectState('CONNECTED');
      })
      .catch(() => {
        setRefesh(refesh + 1);
        setConnectState('ERROR');
      });
  }, [refesh]);

  return { connectState };
};

export default useConnect;
